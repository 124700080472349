.section-seven {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-seven-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;
}

.section-seven-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px;
    margin-top: -8px;
}

.section-seven-testimonials {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
    margin: 40px auto;
    margin-top: 96px;
}

.sst-quote-sign {
    font-family: "Rubik", sans-serif;
    font-size: 90px;
    font-weight: 500;
    margin-top: 32px;
    margin-left: 16px;
    align-self: self-start;
    color: #35183c;
}

.sst-1,
.sst-2,
.sst-3,
.sst-4 {
    width: 500px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
    padding: 20px;
}

.sst-1-p,
.sst-2-p,
.sst-3-p,
.sst-4-p {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #323232;
    opacity: 50%;
    padding: 0 24px 0;
}

.sst-parent-name-1,
.sst-parent-name-2,
.sst-parent-name-3,
.sst-parent-name-4 {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
    color: #f22170;
    padding: 0 20px;
}

.sst-parent-job-1,
.sst-parent-job-2,
.sst-parent-job-3,
.sst-parent-job-4 {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    padding: 0 20px;
}

/* MOBILE */
@media (max-width: 768px) {
    .section-seven {
        margin-top: 0;
    }

    .section-seven-title {
        font-size: 32px;
        text-align: left;
        /* padding: 0 16px; */
        width: calc(100% - 32px);
        margin-top: 0;
        margin-bottom: 32px;
    }

    .section-seven-subtitle {
        font-size: 18px;
        text-align: left;
        /* padding: 0 16px; */
        width: calc(100% - 32px);
        margin-bottom: 0;
    }

    .section-seven-testimonials {
        display: flex;
        overflow-x: auto;
        gap: 16px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding: 32px 16px 32px 16px; /* Add space for scrollbar */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
        scrollbar-width: none; /* Hide scrollbar for Firefox */
        -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    }
    
    .section-seven-testimonials::-webkit-scrollbar {
        display: none; /* WebKit */
    }

    .sst-1,
    .sst-2,
    .sst-3,
    .sst-4 {
        flex: 0 0 auto;
        width: 100%; /* Adjust card width for mobile */
        max-width: 280px;
        box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .sst-1-p,
    .sst-2-p,
    .sst-3-p,
    .sst-4-p {
        margin: 0;
    }
}