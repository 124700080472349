.activity-providers-list {
    font-family: "Rubik", sans-serif;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }
  
.apl-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.apl-list-title h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
  }
  
.apl-list-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;

    /* border: 1px solid blue; */
  }
  
.apl-table-container {
    overflow-x: auto;
    margin-top: 32px;
    width: 100%;
  }

.apl-table-container table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
  
.apl-table-container th, 
.apl-table-container td {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #35183c;
    padding: 8px 16px;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  /* THIS line of code is the core problem */
  }
  
.apl-table-container th {
    background-color: #f9f9f9;
    /* border-radius: 10px; */
    color: #35183c;
    font-weight: 500;
    height: 40px;
  }

  .apl-name-column {
    width: 400px;
  }

  .apl-category-column {
    width: 100px;

  }
  
  .apl-hcname-column {
    width: 150px;
  }
  
  .apl-phone-column {
    width: 150px;
  }

  .apl-email-column {
    width: 150px;
  }
  
  .apl-provider-icon {
    width: 40px;
    height: 40px;
    background-color: #72147e;
    border-radius: 5px;
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle;
  }

  .apl-provider-name {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    text-decoration: none;
    transition: 0.3s ease;
  }

  .apl-loading,
  .apl-error {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #35183c;
    text-align: center;
    margin-top: 24px;
  }

  .apl-error {
    color: #f21170;
  }

.apl-subscription-status-select {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  /* padding: 8px; */
  /* border: 1px solid #35183c; */
  /* border-radius: 10px; */
  border: none;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.apl-next-payment-date-cell {
  cursor: pointer;
  position: relative;
}

.apl-subscription-next-payment-date {
  position: absolute;
  opacity: 0;
  pointer-events: none;  
}

.apl-subscription-status-select:focus,
.apl-subscription-next-payment-date:focus {
  outline: none;
  /* border-color: #72147e; */
  /* box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2) */
}

/* ID COLUMN */
.apl-id-column {
  font-family: monospace;
}

/* STATUS COLUMN */
.apl-status {
  font-weight: 500;
}

.apl-status.complete {
  color: #2ecc71
}

.apl-status.incomplete {
  color: #f21170;
}

/* DELETE ACTIVITY PROVIDER ACCOUNT */
.apl-delete-activity-provider-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  padding: 8px 16px;
  background-color: #323232;
  opacity: 50%;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.apl-delete-activity-provider-button:hover {
  opacity: 100%;
}
  