.special-offer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    /* box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid #F21170; */
}
  
.special-offer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.special-offer-content-left {
    display: flex;
    align-items: center;
}

.special-offer-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #35183C;
    padding: 8px;
}

.special-offer-icon {
    color: #F21170;
    padding: 0 8px;
    margin-right: 8px;
}

.special-offer-button {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #F21170;
    color: white;
    border: none;
    border-radius: 10px;
    margin-right: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.special-offer-button:hover {
    background-color: #72147e;
}

@media (max-width: 768px) {

    .special-offer-content {
        flex-direction: column;
        gap: 16px;
    }

    .special-offer-content-left {
        width: 100%;
    }

    .special-offer-button {
        width: 100%;
        margin-right: 0;
    }
}