.pricing-container {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
  
.pricing-card {
  display: flex;
  flex-flow: column wrap;
  align-content: left;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 24px;
  margin: 16px;
  text-align: left;
  width: 100%;
  max-width: 220px;
  height: 280px;
  box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
}
  
.pricing-field1 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #35183C;
  margin-bottom: 0;
}
  
.pricing-field2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  color: #35183C;
  padding: 16px 0;
  overflow-x: auto;
  border-bottom: 1px solid #ddd;
}
  
.pricing-field3 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 50px;
  font-weight: 700;
  color: #35183C;
  margin-top: 16px;
}
  
.pricing-field4 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #35183C;
  margin-top: 32px;
  display: flex; /* 'display' and 'align-items' in this combination align text and icon */
  align-items: center;
}
  
.pricing-field5 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #35183C;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.pf4-icon-item,
.pf5-icon-item {
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  opacity: 0.25;
  margin-right: 8px;
}

.pricing-carousel {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  width: 100%;
  padding: 16px 32px;
  cursor: grab;
}

.pricing-carousel::-webkit-scrollbar {
  display: none;  /* WebKit */
}

.pricing-carousel .pricing-card {
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-right: 16px;
}

.pricing-carousel .pricing-card:last-child {
  margin-right: 0;
}

.pricing-carousel.fewer-cards {
  justify-content: center;
}

@media (max-width: 768px) {
  .pricing-container {
    padding: 32px 16px;
  }

  .pricing-carousel {
    padding: 0 16px;
  }
  
  .pricing-card {
    margin: 32px;
  }
}