.breadcrumb-container {
    margin: 0;
    padding: 10px 0 0;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 16px 0;
    padding: 0;
    align-items: baseline;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 8px;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 8px;
    color: #323232;
    opacity: 0.5;
    content: ">";
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    opacity: 0.5;
    text-decoration: none;
    text-transform: capitalize;
  }

  /* MOBILE */
.breadcrumb-container {
    margin: 0 20px 0;
    padding: 0;
}

