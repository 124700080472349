.parent-login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    padding: 0 20px;
  }
  
  .pl-usp-container {
    width: 45%;
  }
  
  .pl-usp-container h1 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 24px;
  }

  .pl-usp-container h1 span {
    color: #f21170;
  }
  
  .pl-usp-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pl-usp-container li {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    color: #35183c;
    margin-bottom: 10px;
  }
  
  .pl-usp-container p {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
    margin-top: 24px;
    cursor: pointer;
  }
  
  .pl-login-form-container {
    width: 50%;
    max-width: 400px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
  }
  
  .pl-login-form-container h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 24px;
    text-align: center;
  }
  
  .pl-form-group {
    margin-bottom: 16px;
  }
  
  .pl-form-group input {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .pl-form-group input:focus {
    outline: none;
    border-color: #72147e;
    box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
  }
  
  .pl-login-form-container button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 16px;
    padding: 10px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .pl-login-form-container button:hover {
    background-color: #72147e;
  }
  
  .pl-error {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #f21170;
    margin-top: 8px;
  }

.pl-forgot-password {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #35183c;
    text-align: center;
    margin-top: 16px;
    cursor: pointer;
}

.pl-forgot-password span {
    color: #f21170;
    cursor: pointer;
}

.pl-forgot-password span:hover {
  text-decoration: underline;
}

.pl-reset-sent {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
    text-align: center;
    margin-top: 16px;
}
  
.pl-signup-link {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #35183c;
    text-align: center;
    margin-top: 8px;
}
  
.pl-signup-link span {
    color: #f21170;
    cursor: pointer;
}
  
.pl-signup-link span:hover {
    text-decoration: underline;
}

.pl-mobile-layout {
  display: none;
}

/* MOBILE */
@media (max-width: 768px) {
  .pl-usp-container,
  .pl-login-form-container {
    display: none;
  }

  .parent-login {
    padding: 0 16px;
  }

   /* Show and style mobile layout */
   .pl-mobile-layout {
    display: flex;
    flex-direction: column;
  }

  .pl-mobile-usp-container {
    width: 100%;
    margin-bottom: 0;
  }

  .pl-mobile-usp-container h1 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 16px;
  }

  .pl-mobile-usp-container h1 span {
    color: #f21170;
  }

  .pl-mobile-usp-container ul {
    list-style-type: none;
    padding: 0;
    margin: 16px 0;
  }

  .pl-mobile-usp-container li {
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    margin: 8px 0 0;
  }

  /* LogIn form */
  .pl-mobile-login-form-container {
    width: 100%;
    padding: 0;
    border-radius: 10px;
    box-shadow: none;
  }

  .pl-mobile-login-form-container h2 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin: 0 0 16px;
    text-align: center;
  }

  .pl-mobile-form-group {
    margin-bottom: 16px;
  }

  .pl-mobile-form-group input {
    font-size: 14px;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
    box-sizing: border-box;
    height: 40px;
  }

  .pl-mobile-login-form-container button {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 0;
    padding: 10px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  .pl-mobile-forgot-password,
  .pl-mobile-signup-link {
    font-size: 14px;
    color: #35183c;
    text-align: center;
    margin-top: 24px;
  }

  .pl-mobile-signup-link {
    margin-top: -8px;
  }

  .pl-mobile-forgot-password span,
  .pl-mobile-signup-link span {
    color: #f21170;
    cursor: pointer;
  }

  .pl-mobile-layout > p {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
    margin-top: 24px;
    text-align: center;
  }
}

