.blog-id-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
}

.bic-image-and-title {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  margin-bottom: 32px;
}

.bic-image-and-title::after {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: rgb(0, 0, 0, 0.25);
  z-index: 0;
}

.bic-image {
  position: absolute;
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.bic-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 32px;
  padding-bottom: 8px;
  z-index: 1;
}

.bic-content {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #35183c;
  line-height: 1.6;
  width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.bic-content p:empty {
  display: none;
}

.bic-content p:only-child {
  margin-bottom: 0;
}

.bic-content p br:only-child {
  display: none;
}

.bic-content h1,
.bic-content h2,
.bic-content h3 {
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 16px;
}

.bic-content h1 {
  font-size: 28px;
}
.bic-content h2 {
  font-size: 24px;
}
.bic-content h3 {
  font-size: 20px;
}

.bic-content p {
  margin-bottom: 16px;
}

.bic-content ul,
.bic-content ol {
  margin-left: 24px;
  margin-bottom: 16px;
}

.bic-content li {
  margin-bottom: 8px;
}

/* Add styles for blockquotes */
.bic-content blockquote {
  border-left: 4px solid #f21170;
  padding-left: 16px;
  margin: 16px 0;
  font-style: italic;
}

/* Add styles for images within the content */
.bic-content img {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
}

/* Add styles for links within the content */
.bic-content a {
  color: #f21170;
  text-decoration: none;
}

.bic-content a:hover {
  text-decoration: underline;
}

/* MOBILE */
@media (max-width: 768px) {
  .blog-id-container {
    width: calc(100% - 32px);
  }

  .bic-content {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .bic-content ul,
  .bic-content ol {
    margin-left: 0;
  }
}
