.activity-marker {
    position: relative;
    display: flex;
    background-color: #ffffff;
    opacity: 95%;
    border-radius: 10px;
    padding: 8px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-bottom: 16px;
  }

  .activity-marker::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .am-logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 8px;
  }
  
  .am-info {
    flex: 1;
    align-content: center;
  }
  
  .am-business-name {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #35183c;
    margin: 0 0 4px;
    max-width: 234px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .am-details {
    display: flex;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #35183c;
  }
  
  .am-price, .am-reviews {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .am-reviews {
    display: flex;
    align-items: center;
    margin: 0 8px;
  }
  
  .am-icon {
    margin-right: 4px;
    color: #323232;
    opacity: 0.5;
  }
  
  .am-reviews {
    cursor: pointer;
  }