.review-cta {
  max-width: 1100px;
  margin: 0 auto;
  padding: 24px 20px;
  text-align: center;
  background-color: #ffffff;
  font-family: "Rubik", sans-serif;
}

.review-cta-text {
  font-size: 16px;
  font-weight: 500;
  color: #35183c;
  margin-bottom: 16px;
}

.review-cta-button {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  background-color: #f21170;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.review-cta-button:hover {
  background-color: #72147e;
}

@media (max-width: 768px) {
  .review-cta {
    padding: 16px;
  }
}
