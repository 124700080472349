.section-six {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-six-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;        
}

.section-six-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px ;
    margin-top: -8px;       
}
.activity-providers-logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 8px;
    width: 100%;
    margin-top: 64px;
}

.apl-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
}

.apl-wrapper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* MOBILE */
@media (max-width: 768px) {
    .section-six {
        margin-top: 32px;
    }

    .section-six-title {
        font-size: 32px;
        text-align: left;
        /* padding: 0 16px; */
        width: calc(100% - 32px);
        margin-top: 0;
        margin-bottom: 32px;
    }

    .section-six-subtitle {
        font-size: 18px;
        text-align: left;
        /* padding: 0 16px; */
        width: calc(100% - 32px);
        margin-bottom: 32px;
    }

    .activity-providers-logo-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding: 0 16px;
        margin-top: 32px;
    }

    .apl-wrapper {
        height: 148px;
    }
}