.privacy-policy-container {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .pp-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 40px;
    font-weight: 600;
    color: #35183c;
    margin-bottom: 16px;
  }
  
  .pp-last-updated {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 0.5;
    margin-bottom: 32px;
  }
  
  .pp-content {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    line-height: 1.6;
  }

/* THIS removes unnecessary <p>'s from the content */
.pp-content p:empty {
    display: none;
}

.pp-content p:only-child {
    margin-bottom: 0;
}

.pp-content p br:only-child {
    display: none;
}
/* THIS removes unnecessary <p>'s from the content --END */

  .pp-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  
  .pp-content p {
    margin-bottom: 16px;
  }
  
  .pp-content ul, .tos-content ol {
    margin-bottom: 16px;
    padding-left: 20px;
  }
  
  .pp-content li {
    margin-bottom: 8px;
  }
  
  /* MOBILE */
  @media (max-width: 768px) {
    .privacy-policy-container {
      width: 92%;
      margin: 16px;
      padding: 0;
    }
  
    .pp-title {
      font-size: 32px;
    }
  
    .pp-content h2 {
      font-size: 20px;
    }
  }