/* QuillCustomStyles.css */

/* Custom Quill container */
.ql-container {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}
  
  /* Toolbar styles */
  .ql-toolbar {
    border: 2px solid #35183c;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
}
  
  /* Editor styles */
  .ql-editor {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    min-height: 200px;
}
  
  /* Custom color picker */
  .ql-color .ql-picker-options,
  .ql-background .ql-picker-options {
    padding: 4px;
    width: 152px;
}
  
  .ql-color .ql-picker-item,
  .ql-background .ql-picker-item {
    width: 20px;
    height: 20px;
    margin: 2px;
}
  
  /* Add your brand colors here */
  .ql-color .ql-picker-options [data-value="#f21170"],
  .ql-background .ql-picker-options [data-value="#f21170"] {
    background-color: #f21170 !important;
}
  
  .ql-color .ql-picker-options [data-value="#72147e"],
  .ql-background .ql-picker-options [data-value="#72147e"] {
    background-color: #72147e !important;
}
  
  .ql-color .ql-picker-options [data-value="#35183c"],
  .ql-background .ql-picker-options [data-value="#35183c"] {
    background-color: #35183c !important;
}
  
  /* Custom hover and focus styles */
  .ql-toolbar button:hover,
  .ql-toolbar button:focus,
  .ql-toolbar button.ql-active,
  .ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-label.ql-active,
  .ql-toolbar .ql-picker-item:hover,
  .ql-toolbar .ql-picker-item.ql-selected {
    color: #f21170 !important;
}
  
  .ql-toolbar button:hover .ql-stroke,
  .ql-toolbar button:focus .ql-stroke,
  .ql-toolbar button.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-toolbar button:hover .ql-fill,
  .ql-toolbar button:focus .ql-fill,
  .ql-toolbar button.ql-active .ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    stroke: #f21170 !important;
}
  
  .ql-toolbar button:hover .ql-fill,
  .ql-toolbar button:focus .ql-fill,
  .ql-toolbar button.ql-active .ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #f21170 !important;
}


/* Blockquote styles */
.ql-editor blockquote {
  padding-left: 16px;
  margin: 16px 0;
  font-style: italic;
}

/* Alignment styles */
.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

/* Indentation styles */
.ql-editor .ql-indent-1 {
  padding-left: 3em;
}

.ql-editor .ql-indent-2 {
  padding-left: 6em;
}

/* Add more indent levels as needed */

/* RTL text direction */
.ql-editor[dir="rtl"] {
  direction: rtl;
  text-align: right;
}