.add-privacy-form-container {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
  }
  
  .add-privacy-form-container h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #35183c;
  }
  
  .add-privacy-form {
    border-radius: 10px;
    height: 70vh;
    overflow-y: auto;
    padding: 20px;
    scrollbar-width: none;
  }
  
  .add-privacy-form::-webkit-scrollbar {
    display: none;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;
    display: block;
    color: #35183c;
  }
  
  .form-group input[type="text"],
  .form-group input[type="date"],
  .form-group textarea {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
  }
  
  .form-group textarea {
    resize: vertical;
  }

  .submit-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 16px;
    padding: 0 80px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #72147e;
  }

  .privacy-error-message {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: #f21170;
    text-align: center;
    padding: 0 0 24px;
  }