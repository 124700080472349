.blog-page-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.bh-image-and-title {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.bh-image-and-title::after {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: rgb(0, 0, 0, 0.25);
  z-index: 0;
}

.bh-image {
  position: absolute;
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.bh-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 32px;
  padding-bottom: 8px;
  z-index: 1;
}

.bh-sub-content {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 64px;
}

.bh-annotation {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
}

.bh-date {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  /* margin: 8px 0; */
  color: #323232;
  opacity: 50%;
}

.bh-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  background-color: #72147e;
  color: white;
  border: none;
  border-radius: 10px;
  /* width: 200px; */
  height: 40px;
  margin-top: 32px;
}

.bh-button:hover {
  background-color: #72147e;
  transition: background-color 0.3s;
}

/* SECTION WITH OTHER ARTICLES */

.bpc-blog-articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.bpc-blog-card {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  /* margin-top: 32px; */
  width: 340px; /* Adjust as needed */
  text-align: left;
}

.bpc-blog-article-image-container {
  position: relative;
}

.bpc-blog-article-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
}

.bpc-blog-artilce-title-container {
  height: 60px;
  display: flex;
  align-items: center; /* center / first baseline */
  overflow: hidden;
}

.bpc-blog-article-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  color: #35183c;
  margin: 16px 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bpc-blog-article-annotation {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
}

.bpc-blog-article-date {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  opacity: 0.5;
  margin: 8px 0;
}

.bpc-read-the-article-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  background-color: #f21170;
  color: white;
  border: none;
  border-radius: 10px;
  width: 340px;
  height: 40px;
  margin-top: 16px;
}

.bpc-read-the-article-button:hover {
  background-color: #72147e;
  transition: background-color 0.3s;
}

.bpc-load-more-button-container {
  display: flex;
  justify-content: center;
  margin: 32px 0;
}

.bpc-load-more-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  background-color: #f21170;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  cursor: pointer;
}

.bpc-load-more-button:hover {
  background-color: #72147e;
}

/* MOBILE */
@media (max-width: 768px) {
  .blog-page-container {
    width: calc(100% - 32px);
  }

  .bh-sub-content {
    margin-bottom: 0;
  }

  .bh-annotation {
    margin: 16px 0;
  }

  .bh-button {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .bpc-blog-articles {
    margin-top: 16px;
    grid-template-columns: 1fr;
  }

  .bpc-blog-card {
    width: 100%;
    margin-bottom: 0;
  }

  .bpc-blog-article-annotation {
    margin: 0 0 16px 0 ;
  }

  .bpc-blog-article-date {
    margin: 0;
  }

  .bpc-read-the-article-button {
    width: 100%;
    background-color: #72147e;
  }

  .bpc-load-more-button-container {
    margin: 16px 0;
  }

  .bpc-load-more-button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
}
