.groups {
    padding: 0 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
  }

  .group {
    margin-bottom: 16px;
  }
  
  .group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;
  }

  .group-header h3 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    cursor: pointer;
    margin-bottom: 16px;
    color: #35183C; 
  }
  
  .toggle-icon {
    font-size: 20px;
    margin-left: 8px;
    color: #35183C; 
    /* transition: all 0.3s ease; */
  }
  
  .group-description {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #35183C;
    padding: 0 8px 36px;
  }
  
  /* Quill specific styles */
  .group-description .ql-editor {
    padding: 0;
  }
  
  .group-description p {
    margin-bottom: 16px;
  }

  .group-description p:empty {
    display: none;
  }

  .group-description p:only-child {
    margin-bottom: 0;
  }

  .group-description p br:only-child {
    display: none;
  }
  
  .group-description ul, 
  .group-description ol {
    margin-left: 24px;
    margin-bottom: 16px;
  }
  
  .group-description li {
    margin-bottom: 8px;
  }
  
  .group-description h1, 
  .group-description h2, 
  .group-description h3 {
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  
  .group-description h1 { font-size: 28px; }
  .group-description h2 { font-size: 24px; }
  .group-description h3 { font-size: 20px; }
  
  .group-description a {
    color: #f21170;
    text-decoration: none;
  }
  
  .group-description a:hover {
    text-decoration: underline;
  }
  
  .group-description blockquote {
    border-left: 4px solid #f21170;
    padding-left: 16px;
    margin: 16px 0;
    font-style: italic;
  }
  
  .group-description img {
    max-width: 100%;
    height: auto;
    margin: 16px 0;
  }
  