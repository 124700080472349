.hero-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    height: 500px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.hero-container::after {
    content: '';
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 500px;
    background-color: rgb(0, 0, 0, 0.25);
    z-index: -1;
}

.hero-image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
} 

.hc-hero-title {
    font-size: 96px;
    font-weight: bold;
    color:#ffffff;
}

.hc-hero-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
}

.s-usp-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 10px;
    width: 100%;
    margin: 40px auto;
}

.s-usp-1,
.s-usp-2,
.s-usp-3,
.s-usp-4,
.s-usp-5,
.s-usp-6 {
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.s-usp-1:hover,
.s-usp-2:hover,
.s-usp-3:hover,
.s-usp-4:hover,
.s-usp-5:hover,
.s-usp-6:hover {
    font-weight: 500;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s;

}

.s-usp-1-p,
.s-usp-2-p,
.s-usp-3-p,
.s-usp-4-p,
.s-usp-5-p,
.s-usp-6-p {
    padding: 0 32px 10px;
    font-size: 16px;
    color: #323232;
    opacity: 50%;
}


/* LIST WITH FAIRYTALES*/
.list-container {
    margin: 0 auto;
    margin-bottom: 32px;
    /* Update this to use content-container styles */
    width: 100%;
    max-width: 1100px;
    box-sizing: border-box;
}

.skazka-image-and-title {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.skazka-image-and-title::after {
    content: '';
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-color: rgb(0, 0, 0, 0.25);
    z-index: 0;
}

.skazka-image {
    position: absolute;
    width: 100%;
    height: 500px; 
    object-fit: cover;
    border-radius: 10px;
}

.skazka-title {
    font-size: 40px;
    color: #ffffff;
    padding-left: 32px;
    padding-bottom: 8px;
    z-index: 1;
}

.skazka-description {
    font-size: 16px;
    text-align: left;
    color: #35183C;
    margin-bottom: 32px;
}

.skazka-button {
    font-size: 16px;
    font-weight: bold;
    background-color: #F21170;
    color: #ffffff;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 32px;
}

.skazka-button:hover {
    background-color: #72147e;
}

/* MOBILE */
@media (max-width: 768px) {
    .hero-container,
    .list-container {
        width: 92%;
        margin: 16px;
    }

    .hc-hero-title {
        font-size: 48px;
        margin: 16px;
    }

    .hc-hero-subtitle {
        font-size: 24px;
        text-align: left;
        margin: 16px
    }

    .s-usp-grid {
        display: flex;
        flex-wrap: nowrap; 
        overflow-x: auto; 
        padding: 16px;
        gap: 20px;
        justify-content: space-between;
        margin-top: 32px;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
        scrollbar-width: none; /* Hide scrollbar for Firefox */
        -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    }

    .s-usp-1-p,
    .s-usp-2-p,
    .s-usp-3-p,
    .s-usp-4-p,
    .s-usp-5-p,
    .s-usp-6-p {
        padding: 32px 32px;
        width: 200px;
        height: 60px;
        font-size: 16px;
        color: #323232;
        opacity: 50%;
    }

    .s-usp-grid::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
      }
}
