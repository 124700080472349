.admin-login {
    width: 50%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 128px;
    padding: 60px;
    position: relative;
    border-radius: 10px;

    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
}

.admin-login h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;

    text-align: center;
    margin-bottom: 24px;
    color: #35183c;
}

.admin-login form div {
    margin-bottom: 16px;
}

.admin-login-input-group {
    margin-bottom: 16px;
    display: flex;
}

.admin-login input {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;

    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
}

.admin-login input::placeholder {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;

    color: #323232;
    opacity: 50%;
}

.admin-login button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;

    width: 100%;
    margin-top: 16px;
    padding: 0 80px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.admin-login button:hover {
    background-color: #72147e;
}

.admin-login .error {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;

    margin-top: 8px;
    color: #f21170;
}