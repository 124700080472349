.activity-provider-signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    padding: 0 20px;
  }
  
  .ap-usp-container {
    width: 45%;
  }
  
  .ap-usp-container h1 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 24px;
  }

  .ap-usp-container h1 span {
    color: #f21170;
  }
  
  .ap-usp-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .ap-usp-container li {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    color: #35183c;
    margin-bottom: 10px;
  }
  
  .ap-usp-container p {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
    margin-top: 24px;
    cursor: pointer;
  }

  .ap-signup-form-container {
    width: 50%;
    max-width: 400px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
  }
  
  .ap-signup-form-container h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 24px;
    text-align: center;
  }
  
  .ap-form-group {
    margin-bottom: 16px;
  }
  
  .ap-form-group input {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .ap-form-group input:focus {
    outline: none;
    border-color: #72147e;
    box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
  }
  
  .ap-signup-form-container button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 16px;
    padding: 10px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .ap-signup-form-container button:hover {
    background-color: #72147e;
  }
  
  .ap-error {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #f21170;
    margin-top: 8px;
  }
  
  .ap-login-link {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    color: #35183c;
    text-align: center;
    margin-top: 16px;
  }
  
  .ap-login-link span {
    color: #f21170;
    cursor: pointer;
  }
  
  .ap-login-link span:hover {
    text-decoration: underline;
  }

  .ap-mobile-layout {
    display: none;
  }

  /* MOBILE */
@media (max-width: 768px) {
  .ap-usp-container,
  .ap-signup-form-container {
      display: none;
  }

  .activity-provider-signup {
      padding: 0 16px;
  }

  /* Show and style mobile layout */
  .ap-mobile-layout {
      display: flex;
      flex-direction: column;
  }

  .ap-mobile-usp-container {
      width: 100%;
      margin-bottom: 0;
  }

  .ap-mobile-usp-container h1 {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      color: #35183c;
      margin-bottom: 16px;
  }

  .ap-mobile-usp-container h1 span {
      color: #f21170;
  }

  .ap-mobile-usp-container ul {
      list-style-type: none;
      padding: 0;
      margin: 16px 0;
  }

  .ap-mobile-usp-container li {
      font-size: 16px;
      font-weight: 400;
      color: #35183c;
      margin: 8px 0 0;
  }

  /* SignUp form */
  .ap-mobile-signup-form-container {
      width: 100%;
      padding: 0;
      border-radius: 10px;
      box-shadow: none;
  }

  .ap-mobile-signup-form-container h2 {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: #35183c;
      margin: 0 0 16px;
      text-align: center;
  }

  .ap-mobile-form-group {
      margin-bottom: 16px;
  }

  .ap-mobile-form-group input {
      font-size: 14px;
      width: 100%;
      padding: 8px;
      border: 1px solid #35183c;
      border-radius: 10px;
      box-sizing: border-box;
      height: 40px;
  }

  .ap-mobile-signup-form-container button {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      margin-top: 0;
      padding: 10px;
      height: 50px;
      background-color: #f21170;
      color: #ffffff;
      border: none;
      border-radius: 10px;
      cursor: pointer;
  }

  .ap-mobile-login-link {
      font-size: 14px;
      color: #35183c;
      text-align: center;
      margin-top: 24px;
  }

  .ap-mobile-login-link span {
      color: #f21170;
      cursor: pointer;
  }

  .ap-mobile-layout > p {
      font-size: 12px;
      font-weight: 400;
      color: #323232;
      opacity: 50%;
      margin-top: 24px;
      text-align: center;
  }
}