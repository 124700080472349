.activity-provider-settings {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aps-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aps-title h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
}

.aps-form-container {
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  padding: 16px;
  scrollbar-width: none;
}

.aps-form-container::-webkit-scrollbar {
  display: none;
}

.aps-form-group {
  margin-bottom: 16px;
  width: 100%;
}

.aps-form-group label {
  font-family: "Rubik", sans-serif;
  display: block;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  color: #35183c;
  margin-bottom: 8px;
}

.aps-form-group input {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  padding: 8px;
  border: 1px solid #35183c;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.aps-form-group input:focus {
  outline: none;
  border-color: #72147e;
  box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
}

.aps-submit-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-top: 16px;
  padding: 0 80px;
  height: 50px;
  background-color: #f21170;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.aps-submit-button:hover {
  background-color: #72147e;
}

.aps-error,
.aps-success {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.aps-error {
  background-color: #ffebee;
  color: #f44336;
}

.aps-success {
  background-color: #e8f5e9;
  color: #4caf50;
}

.aps-loading {
  text-align: center;
  color: #35183c;
}

.aps-form-divider {
  border: none;
  border-top: 1px solid #323232;
  opacity: 25%;
  margin-top: 48px;
  margin-bottom: 48px;
}

/* DANGER ZONE */
.aps-danger-zone {
  margin-bottom: 24px;
}

.aps-danger-zone h3 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  opacity: 50%;
}

.aps-danger-zone p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  opacity: 50%;
}

.aps-danger-zone input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #323232;
  opacity: 50%;
  border: 1px solid #35183c;
  border-radius: 10px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.aps-danger-zone input:focus {
  outline: none;
  border-color: #323232;
  box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
}

.aps-delete-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-top: 16px;
  padding: 0 80px;
  height: 50px;
  background-color: #323232;
  opacity: 50%;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  margin-bottom: 24px;
}

.aps-delete-button:hover {
  background-color: #323232;
  opacity: 100%;
}

/* MOBILE */
@media (max-width: 768px) {

  .activity-provider-settings {
    align-items: flex-start;
    padding: 16px;
  }

  .aps-form-container {
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .activity-provider-settings .aps-title h2 {
    font-size: 20px;
    text-align: left;
    padding: 0;
    margin: 16px 0 8px;
  }
}
