.add-special-offer-container {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
  }
  
  .add-special-offer-container h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #35183c;
  }
  
  .add-special-offer-form {
    border-radius: 10px;
    height: 70vh;
    overflow-y: auto;
    padding: 20px;
    scrollbar-width: none;
  }

  .add-special-offer-form::-webkit-scrollbar {
    display: none;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #35183c;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group textarea,
  .form-group select {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 10px;
    border: 1px solid #35183c;
    border-radius: 10px;
  }

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: #72147e;
    box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
}
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .date-range .date-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .date-range input[type="date"] {
    width: 45%;
  }
  
  .date-range span {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
  }
  
  .submit-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    padding: 12px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #72147e;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #f21170;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: center;
  }