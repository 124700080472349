.parent-signup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
  padding: 0 20px;
}

.ps-usp-container {
  width: 45%;
}

.ps-usp-container h1 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
  margin-bottom: 24px;
}

.ps-usp-container h1 span {
  color: #f21170;
}

.ps-usp-container ul {
  list-style-type: none;
  padding: 0;
}

.ps-usp-container li {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  color: #35183c;
  margin-bottom: 10px;
}

.ps-usp-container p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  opacity: 50%;
  margin-top: 24px;
  cursor: pointer;
}

.ps-signup-form-container {
  width: 50%;
  max-width: 400px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
}

.ps-signup-form-container h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
  margin-bottom: 24px;
  text-align: center;
}

.ps-form-group {
  margin-bottom: 16px;
}

.ps-form-group input {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  padding: 8px;
  border: 1px solid #35183c;
  border-radius: 10px;
  box-sizing: border-box;
}

.ps-form-group input:focus {
  outline: none;
  border-color: #72147e;
  box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
}

.ps-signup-form-container button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-top: 16px;
  padding: 10px;
  height: 50px;
  background-color: #f21170;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.ps-signup-form-container button:hover {
  background-color: #72147e;
}

.ps-error {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #f21170;
  margin-top: 8px;
}

.ps-login-link {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #35183c;
  text-align: center;
  margin-top: 16px;
}

.ps-login-link span {
  color: #f21170;
  cursor: pointer;
}

.ps-login-link span:hover {
  text-decoration: underline;
}

.ps-mobile-layout {
  display: none;
}

/* MOBILE */
@media (max-width: 768px) {
  .ps-usp-container,
  .ps-signup-form-container {
    display: none;
  }

  .parent-signup {
    padding: 0 16px;
  }

  /* Show and style mobile layout */
  .ps-mobile-layout {
    display: flex;
    flex-direction: column;
  }

  .ps-mobile-usp-container {
    width: 100%;
    margin-bottom: 0;
  }

  .ps-mobile-usp-container h1 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin-bottom: 16px;
  }

  .ps-mobile-usp-container h1 span {
    color: #f21170;
  }

  .ps-mobile-usp-container ul {
    list-style-type: none;
    padding: 0;
    margin: 16px 0;
  }

  .ps-mobile-usp-container li {
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    margin: 8px 0 0;
  }

  /* SignUp form */
  .ps-mobile-signup-form-container {
    width: 100%;
    padding: 0;
    border-radius: 10px;
    box-shadow: none;
  }

  .ps-mobile-signup-form-container h2 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
    margin: 0 0 16px;
    text-align: center;
  }

  .ps-mobile-form-group {
    margin-bottom: 16px;
  }

  .ps-mobile-form-group input {
    font-size: 14px;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    border-radius: 10px;
    box-sizing: border-box;
    height: 40px;
  }

  .ps-mobile-signup-form-container button {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    margin-top: 0;
    padding: 10px;
    height: 50px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  .ps-mobile-login-link {
    font-size: 14px;
    color: #35183c;
    text-align: center;
    margin-top: 24px;
  }

  .ps-mobile-login-link span {
    color: #f21170;
    cursor: pointer;
  }

  .ps-mobile-layout > p {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
    margin-top: 24px;
    text-align: center;
  }
}
