.edit-activity-form-container {
  max-width: 800px;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}

.edit-activity-form-container h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #35183c;
}

.edit-activity-form {
  border-radius: 10px;
  height: 70vh;
  overflow-y: auto;
  padding: 14px;
  scrollbar-width: none;
}

.edit-activity-form::-webkit-scrollbar {
  display: none;
}

.edit-activity-form form {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.eaf-preview-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 0 80px;
  height: 50px;
  background-color: #72147e;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 0;
}

.eaf-submit-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-top: 16px;
  padding: 0 80px;
  height: 50px;
  background-color: #f21170;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
}

/* .eaf-submit-button:hover {
  background-color: #72147e;
} */

/* Preview Banner */
.preview-banner {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #72147e;
  color: white;
  padding: 8px 16px;
  border-radius: 0 0 10px 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  z-index: 1001;
}

.eaf-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  z-index: 1000;
}

.form-group input,
.form-group textarea,
.form-group select,
.bif-image-upload-area,
.bif-logo-upload-area,
.offering-form-item textarea,
.groups-form-item-one input,
.groups-form-item-two textarea,
.igf-image-upload-area,
.hcf-image-upload-area,
.coach-form-item input,
.coach-form-item textarea,
.pricing-form-item input,
.testimonial-form-item-one input,
.testimonial-form-item-two input,
.testimonial-form-item-one textarea,
.contact-form-item input {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #35183c;
  border-radius: 10px;
}

.eaf-loading,
.eaf-error {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 20px;
}

.eaf-loading {
  color: #35183c;
}

.eaf-error {
  color: #f21170;
  background-color: #ffe5e5;
  border-radius: 10px;
}

.eaf-error-message {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: #ffe5e5;
  color: #f21170;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  display: block;
}

.eaf-auto-save-indicator {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  top: 24px;
  right: 24px;
  background-color: #72147e;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  z-index: 1000;
}

/* MOBILE */
@media (max-width: 768px) {
  .edit-activity-form-container {
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .edit-activity-form-container h2 {
    text-align: left;
    font-size: 20px;
    padding: 0 16px;
    margin: 18px 0 0;
  }

  .edit-activity-form {
    padding: 16px;
  }
}
