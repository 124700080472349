.header {
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  height: 60px;
  max-width: 1100px;
  width: calc(100% - 16px);
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 98%;
}

.header--content {
  position: fixed;
}

.header--map {
position: relative;
}

.header.slide-up {
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

/* Hide city selector by default for non-MapHome pages */
.header--content .city-selector,
.header--content ~ .menu-modal .modal-menu-city-selector {
  display: none;
}

.header-logo-name-location {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header img {
  width: 40px;
  height: 50px;
  cursor: pointer;
}

.header p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  color: #72147e;
  margin: 0; /* Space between the elements */
  cursor: pointer;
}

.header-burger-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Menu Modal */
.menu-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1004;
}

/* MenuModal START */
.menu-modal {
  background-color: white;
  width: calc(100% - 56px);
  height: 100%;
  max-height: 88vh;
  padding: 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.menu-nav ul {
  list-style-type: none;
  padding: 0 12px;
  margin-top: 0;
}

.menu-nav li {
  margin-bottom: 12px;
}

.menu-nav a {
  text-decoration: none;
  color: #35183c;
  font-size: 18px;
  font-weight: 400;
}

.menu-nav a:hover {
  color: #72147e;
}

/* MenuModal END */

.header select {
  font-size: 14px;
  color: #35183c;
  padding-left: 0;
  padding-right: 16px;
  margin-left: 0;
}

.header .partner-login-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #35183c;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: auto;
}

.header .partner-join-us-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  border: none;
  font-size: 16px;
  font-weight: 600;
  background-color: #f21170;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  /* white-space: nowrap; /* Prevents text from wrapping */
  margin-left: 16px; /* Pushes the button to the right edge */
  position: relative;
  right: 0;
}

.account-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #ffffff;
  background-color: #72147e;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.account-button:hover {
  background-color: #f21170;
}

.partner-join-us-button:hover {
  background-color: #72147e;
  transition: background-color 0.3s;
}

/* New styles for city-select */
.city-selector {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: none;
  color: #35183c;
  font-size: 14px;
  padding: 8px 32px 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%2335183c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19 9l-7 7-7-7"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.city-selector::-ms-expand {
  display: none;
}

.city-selector:focus {
  outline: none;
}

/* MOBILE */
@media (max-width: 768px) {
  /* Adjust header size and padding for a tighter fit on mobile screens */
  .header {
    height: 60px;
    padding: 0 16px;
    justify-content: space-between;
    /* margin-top: 8px; */
  }

  .header-logo-name-location {
    gap: 8px;
  }

  /* Resize the logo to fit within the smaller header */
  .header img {
    width: 30px;
    height: 38px;
  }

  /* Reduce the size of the platform name text */
  .header p {
    font-size: 18px; 
    margin-left: 4px;
    margin-right: 0;
  }

  /* Burger menu*/
  .header-burger-menu {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }

  /* Ensure the city selector is compact but still legible */
  .header select {
    display: none;
  }

  /* Hiding the 'partner login' button to reduce clutter */
  .header .partner-login-button {
    display: none;
  }

  .header .partner-join-us-button {
    font-size: 12px;
    padding: 8px 12px;
    min-width: 90px;
    margin: 0;
  }

  .account-button {
    font-size: 12px;
    padding: 8px 12px;
    min-width: 36px;
    justify-content: center;
  }

  .account-text {
    display: none;
  }

  .city-selector {
    display: none;
  }

  .modal-menu-city-selector {
    margin: 32px 0 16px 0;
  }

  .modal-city-selector {
    width: 100%;
    padding: 12px;
    border: 1px solid #35183c;
    border-radius: 10px;
    font-size: 16px;
    color: #35183c;
  }
}