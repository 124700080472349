.parent-dashboard {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pd-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 24px; */
}

.pd-list-title h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  margin: 24px 0;
  text-transform: uppercase;
  color: #35183c;
}

@media (max-width: 768px) {
  .pd-list-title {
    padding: 0;
  }
}
