.section-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-three-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;
}

.section-three-benefits {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 16px;
    /* width: 60%; */
    margin: 40px auto;
    margin-top: 96px;
}

.number-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #F21170;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-size: 30px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 16px;
}

.stb-usp-1,
.stb-usp-2,
.stb-usp-3,
.stb-usp-4,
.stb-usp-5,
.stb-usp-6,
.stb-usp-7,
.stb-usp-8
{
    width: 300px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.stb-usp-1-p,
.stb-usp-2-p,
.stb-usp-3-p,
.stb-usp-4-p,
.stb-usp-5-p,
.stb-usp-6-p,
.stb-usp-7-p,
.stb-usp-8-p {
    padding: 0 24px 10px;
    /* font-size: 16px; */
    color: #35183c;
    /* opacity: 50%; */
}

/* MOBILE */
@media (max-width: 768px) {
.section-three {
    height: auto;
    margin-top: 32px;
}

.section-three-title {
    font-size: 32px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 16px;
      text-align: left;
}

.section-three-benefits {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 32px 16px 32px 16px; /* Add space for scrollbar */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.section-three-benefits::-webkit-scrollbar {
    display: none; /* WebKit */
}

.stb-usp-1,
.stb-usp-2,
.stb-usp-3,
.stb-usp-4,
.stb-usp-5,
.stb-usp-6 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 16px;
}

.number-circle {
    width: 60px;
    height: 60px;
    font-size: 24px;
    margin-bottom: 16px;
}

.stb-usp-1-p,
.stb-usp-2-p,
.stb-usp-3-p,
.stb-usp-4-p,
.stb-usp-5-p,
.stb-usp-6-p {
    font-size: 18px;
    text-align: left;
    line-height: 110%;
    }
}
