.gallery {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;  
  }
  
  .gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 8px;
  }
  
  .gallery-image {
    width: 100%;
    height: 480px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
  }
