.schedule {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    font-size: 16px;
    /* box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1); */
  
  }
  
  .schedule .days {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    border-bottom: 1px solid #ddd;
  }
  
  .schedule .day {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    color: #35183c;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .schedule .day.active {
    font-weight: 600;
    color: #f21170;
  }
  
  .schedule .day.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: #f21170;
  }
  
  .schedule table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .schedule table th, .schedule table td {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .schedule table th {
    font-weight: 600;
  }