.special-offers-dashboard {
    font-family: "Rubik", sans-serif;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }
  
  .so-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .so-list-title h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
  }
  
  .so-list-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
  }
  
  .so-add-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    padding: 0 40px;
    height: 40px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .so-add-button:hover {
    background-color: #72147e;
  }
  
  .so-add-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .so-table-container {
    overflow-x: auto;
    margin-top: 32px;
    width: 100%;
  }
  
  .so-table-container table {
    width: 100%;
    table-layout: fixed;
  }
  
  .so-table-container th,
  .so-table-container td {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #35183c;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .so-table-container th {
    background-color: #f9f9f9;
    color: #35183c;
    font-weight: 500;
    height: 40px;
  }

  /* Adjust column widths */
.so-table-container th:first-child,
.so-table-container td:first-child {
    width: 40%; /* SpecialOffer Name column */
}

.so-table-container th:nth-child(2),
.so-table-container td:nth-child(2) {
    width: 15%; /* Status column */
}

.so-table-container th:nth-child(3),
.so-table-container td:nth-child(3) {
    width: 10%; /* Views column */
}

.so-table-container th:nth-child(4),
.so-table-container td:nth-child(4) {
    width: 10%; /* Clicks column */
}

.so-table-container th:nth-child(5),
.so-table-container td:nth-child(5) {
    width: 5%; /* Key Actions column */
}

.so-table-container th:nth-child(6),
.so-table-container td:nth-child(6) {
    width: 5%; /* CTR column */
}
  
  /* THIS highlighlights the enitre row when hovering */
  /* .so-table-container tr:hover {
    background-color: #f5f5f5;
  } */
  
  .so-loading,
  .so-error-message {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #35183c;
    text-align: center;
    margin-top: 24px;
  }
  
  .so-error-message {
    color: #f21170;
  }