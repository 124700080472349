.about-us {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    /* box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1); */
  }
  
  .about-us p {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #35183C;
    padding: 8px;
  }