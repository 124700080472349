.hero-section-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: 700px;
    /* border: 2px solid red; */
}

.hero-section-one-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;
    margin-top: -32px;

    /* border: 2px solid red; */
}

.hero-section-one-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px ;
    margin-top: -8px;
    /* border: 2px solid red; */
}

.hero-section-one-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    background-color: #F21170;
    /* background-image: linear-gradient(98.68deg, #72147e -3.73%, #F21170 96.53%); */
    border-radius: 10px;
    /* box-shadow: 0 4px 96px rgba(242, 17, 112, 0.4); */
    margin-top: 40px;
    padding: 0 80px;
    height: 50px;   
}

.hero-section-one-button:hover {
    background-color: #72147e;
    box-shadow: 0 4px 46px rgba(114, 20, 126, 0.3);
    /* background-image: linear-gradient(98.68deg, #72147e -3.73%, #F21170 96.53%); */
    transition: background-color 0.5s;
}

.animated-text {
    position: relative;
    display: inline-block;
    color: inherit;
    transition: color 0.4s ease;
    padding: 0 12px;
}

.animated-text::before {
    content: '';
    position: absolute;
    left: -6px;
    right: -6px;
    top: 0;
    bottom: 0;
    background-color: #F21170;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s ease;
    z-index: -1;
}

.animated-text.animate::before {
    transform: scaleX(1);
}

.animated-char {
    display: inline-block;
    color: inherit;
    transition: color 0.4s ease;
}

.animated-char.space {
    width: 0.3em;
}

.animated-text.animate .animated-char {
    animation: changeColor 0.2s forwards;
}

@keyframes changeColor {
    from {
        color: inherit;
    }
    to {
        color: #ffffff;
    }
}

/* MOBILE */
@media (max-width: 768px) {
    .hero-section-one {
      height: auto;
    }
  
    .hero-section-one-title {
      font-size: 32px;
      margin-top: 8px;
      margin-bottom: 0;
      padding: 32px 16px;
      text-align: left;
    }
  
    .hero-section-one-subtitle {
      font-size: 18px;
      padding: 0 16px 32px;
      margin-top: 0;
      text-align: left;
    }
  
    .hero-section-one-button {
      width: calc(100% - 32px);
      height: 50px;
      font-size: 16px;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
    }
  }