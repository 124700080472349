
/* .App main {
  padding: 60px;
  width: 1100px;
  margin: 0 auto;
} */

.content-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

/* THIS IS THE MAIN LAYOUT FOR ALL PAGES */

body {
  max-width: 1100px;
  margin: 0 auto;

  font-family: "Rubik", sans-serif;
  font-style: normal;
}

/* body.menu-open .map-home-header-wrapper {
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

body.menu-open .map-home-category-bar-wrapper {
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
} */

@media (max-width: 768px) {
  .content-container {
    padding: 10px;
    max-width: 100%;
  }
  html, body {
    width: 100%;
    overflow-x: hidden; 
  }
}