.special-offer-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .special-offer-modal-content {
    background-color: #ffffff;
    border-radius: 10px;
    width: 80%;
    max-width: 720px;
    height: 320px;
    max-height: 80vh;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 2;
  }
  
  .special-offer-modal-body {
    display: flex;
    width: 100%;
    height: 100%;
    /* padding: 20px; */
  }
  
  .special-offer-modal-image {
    flex: 0 0 30%;
    height: 100%;
    /* margin-right: 24px; */
  }
  
  .special-offer-modal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .special-offer-modal-info {
    flex: 1;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
}

.special-offer-modal-info h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #35183C;
    margin-top: 0;
    margin-bottom: 16px;
}

.somi-description {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183C;
    margin-top: 0;
    margin-bottom: 16px;
}

.somi-offer-dates {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #35183C;
    margin-top: 0;
    margin-bottom: 16px;
}

.somi-available-spots {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    color: #F21170;
    margin-top: 0;
    margin-bottom: 16px;
}
  
.special-offer-action-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    background-color: #F21170;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
}
  
.special-offer-action-button:hover {
    background-color: #72147e;
}