/* LoginTypeToggle.css */
.login-type-toggle {
    display: flex;
    gap: 8px;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 8px;
    margin: 32px 0 0;
}

.login-type-toggle .view-btn {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    background: none;
    border: none;
    color: #323232;
    opacity: 50%;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    padding: 0 16px; /* Added for better touch target */
}

.login-type-toggle .view-btn.active {
    color: #72147e;
    font-weight: 500;
    opacity: 100%;
}

.login-type-toggle .view-btn.active::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #72147e;
}

/* Container styles */
.login-page-header {
    width: 100%;
    display: flex;
    justify-content: center;
}