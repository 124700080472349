.map-home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-home-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* HEADER */
.map-home-header-wrapper {
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1002;
  transition: transform 0.3s ease-out;
}

.map-home-header-wrapper.slide-up {
  transform: translateY(-100%) !important;
}

.map-home-header {
  height: 60px;
  max-width: 1100px;
  width: calc(100% - 16px);
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


/* CATEGORY BAR */
.map-home-category-bar-wrapper {
  position: fixed;
  bottom: 8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1002;
  transition: transform 0.3s ease-out;
  margin: 0 auto;
}

.map-home-category-bar-wrapper.slide-down {
  transform: translateY(100%) !important;
}

.map-home-category-bar {
  max-width: 1100px;
  width: calc(100% - 16px);
  padding: 8px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.map-home-category-bar .category-bar {
  padding: 4px 10px;
  gap: 12px;
}

.map-home-category-bar .category-item {
  padding: 0 8px;
}

.map-home-category-bar .category-item.active .category-title {
  margin-top: 0;
}

.map-home-category-bar .category-icon {
  width: 50px;
  height: 50px;
}

.map-home-category-bar .category-title {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.map-container {
  flex-grow: 1;
}

/* CATEGORY ICONS */
.map-category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.map-cb-category-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-category-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: grayscale(0%);
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.map-category-item:hover .map-category-icon {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

/* Adjust the Mapbox popup styles */
.mapboxgl-popup-content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  font-family: "Rubik", sans-serif;
}

.mapboxgl-popup-content h3 {
  color: #35183c;
  font-weight: 600;
  margin-bottom: 5px;
}

.mapboxgl-popup-content p {
  color: #35183c;
  font-size: 14px;
  margin: 5px 0;
}

/* MODAL */
.map-container-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.map-container-modal-activity-provider-page {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 1060px;
  width: calc(100% - 16px);
  max-height: 88%;
  overflow-y: auto;
  bottom: 16px;
  position: center;
}

.mcmapp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* OUTSIDE TERRITORY MODAL */
.mhoct-background-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.mhoct-modal-container-location-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  padding: 24px;
  width: 80%;
  max-width: 480px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.mhoct-modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center;
}

.mhoct-modal-container,
.mhoct-modal-container-info {
  background: white;
  border-radius: 10px;
  padding: 24px;
  width: 80%;
  max-width: 480px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.mhoct-modal-body {
  text-align: left;
}

.mhoct-modal-body h2 {
  color: #35183c;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 16px;
}

.mhoct-modal-body p {
  color: #666;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 16px;
}

/* Already have account? */
.mhoct-login-text {
  margin-top: 16px;
  color: #666;
  font-size: 16px;
}

.mhoct-login-link {
  color: #f21170;
  text-decoration: none;
  font-weight: 500;
}

.mhoct-login-link:hover {
  color: #72147e;
}

.mhoct-social-media-buttons {
  display: flex;
  gap: 24px;
}

.mhoct-social-button {
  flex: 1;
  padding: 12px 0;
  background: #f21170;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.mhoct-social-button:hover {
  background: #72147e;
}

/* MOBILE */
@media (max-width: 768px) {
  .map-modal-close-button {
    font-size: 12px;
    padding: 8px 12px;
    top: 10px;
    right: 10px;
  }

  .map-container-modal-activity-provider-page {
    width: calc(100% - 56px);
  }
}

@media (min-width: 769px) {
  .modal-menu-city-selector {
    display: none;
  }
}
