.activity-provider-dashboard {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.apd-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apd-list-title h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
}

/* TOGGLE */
.apd-subtitle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apd-list-subtitle {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  opacity: 50%;
}

.apd-view-toggle {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 8px;
}

.view-btn {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: none;
  color: #323232;
  opacity: 50%;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
}

.view-btn.active {
  color: #72147e;
  font-weight: 500;
  opacity: 100%;
}

.view-btn.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #72147e;
}

.apd-table-container {
  position: relative;
  margin-top: 32px;
  width: 100%;
}

/* Metrics Grid */
.apd-metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.apd-metric-card {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
}

.metric-label {
  display: block;
  font-size: 14px;
  color: #666;
}

.metric-value {
  display: block;
  font-size: 28px;
  font-weight: 600;
  color: #35183c;
  margin: 4px 0;
  line-height: 1.2;
}

.metric-growth {
  color: #4CAF50;
  font-size: 14px;
}

/* KEY ACTIONS ROW */
.apd-kd-rank-reviews-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.apd-right-column {
  display: grid;
  grid-column: 3 / 5;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

/* Key Actions Section */
.apd-key-actions {
  grid-column: 1 / 3;
  background: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
  /* margin-bottom: 24px; */
}

.apd-key-actions h3 {
  font-size: 18px;
  color: #35183c;
  margin-bottom: 16px;
}

.actions-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 12px;
  margin: 4px 0;
}

.total-actions {
  font-size: 28px;
  font-weight: 600;
  color: #35183c;
  margin-top: 4px 0;
  line-height: 1.2;
}

.actions-grid {
  display: flex;
  gap: 24px;
  margin-right: 88px;
  margin-top: -32px;
  /* align-items: center; */
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  gap: 16px;
  color: #666;
}

/* Individual analytics cards */
.apd-rank-card,
.apd-reviews-card {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.rank-value {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.blur-value {
  filter: blur(5px);
  user-select: none;
}

.metric-subtitle {
  color: #666;
  font-size: 14px;
}

.lock-icon {
  color: #72147e;
  opacity: 0.5;
}

/* Chart Section */
.apd-chart {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
}

.chart-placeholder {
  margin-top: 12px;
  height: 232px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border: 2px dashed #ddd;
  border-radius: 8px;
}

/* Special Offer Container */
.apd-special-offer-container {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
  margin-top: 16px;
}

.apd-special-offer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.apd-special-offer-header h3 {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #35183c;
  margin: 0;
}

.apd-special-offer-actions {
  display: flex;
  gap: 8px;
}

.apd-edit-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #72147e;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.apd-delete-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #f21170;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
}

/* Empty state */
.apd-no-special-offer {
  text-align: center;
  padding: 24px;
}

.apd-no-special-offer p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #35183c;
  margin-bottom: 16px;
}

.apd-add-special-offer-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #72147e;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.apd-edit-button:hover,
.apd-delete-button:hover,
.apd-add-special-offer-button:hover {
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

/* MODAL Glassmorhipsm */
.apd-blur-background {
  filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
}

.apd-glassmorphism-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  width: 80%;
  max-width: 480px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.apd-glassmorphism-modal-body {
  text-align: left;
}

.apd-glassmorphism-modal-container p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #35183C;
  margin: 0;
}

.apd-glassmorphism-action-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  background-color: #FF1F8E;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: 24px;
}

.apd-glassmorphism-action-button:hover {
  background-color: #72147e;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .activity-provider-dashboard {
    padding: 0 16px;
    height: calc(100vh - 120px);
  }

  .apd-metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .actions-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .actions-grid {
    flex-wrap: wrap;
    gap: 12px;
    margin-right: 0;
    margin-top: 0;
  }

  .action-item {
    margin-top: 0;
  }

  .apd-kd-rank-reviews-grid {
    grid-template-columns: 1fr;
  }

  .apd-right-column {
    grid-template-columns: 1fr;
  }
  
  .apd-chart {
    margin-bottom: 16px;
  }

  .chart-placeholder {
    height: 180px;
  }

  .apd-subtitle-row {
    flex-direction: column;
    align-items: normal;
    gap: 8px;
  }

  .view-btn.active {
    padding: 0;
  }

  .view-btn {
    padding: 0;
  }

  .apd-glassmorphism-modal-container {
    width: 76%;
  }

  .apd-glassmorphism-modal-container p {
    font-size: 16px;
  }

  .apd-glassmorphism-action-button {
    font-size: 16px;
  }

  /* SPECIAL OFFERS */
  .apd-special-offer-header {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .apd-special-offer-actions {
    width: 100%;
  }

  .apd-edit-button,
  .apd-delete-button {
    flex: 1;
    text-align: center;
  }
}