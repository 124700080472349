.activity-provider-parents-reviews-list {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}

.aprl-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aprl-list-title h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
}

.aprl-list-count {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  opacity: 0.5;
}

.aprl-error {
  color: #f21170;
  text-align: center;
  padding: 16px;
}

.aprl-empty-state {
  text-align: center;
  padding: 32px;
  color: #666;
  font-family: "Rubik", sans-serif;
}

.aprl-reviews-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.aprl-review-card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 16px;
  transition: all 0.3s ease;
}

.aprl-review-card.top {
  box-shadow: 0 4px 12px rgba(114, 20, 126, 0.5);
}

.aprl-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.aprl-review-header h3 {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #35183c;
  margin: 0;
}

.aprl-review-content {
  margin-bottom: 16px;
}

.aprl-review-content span {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #35183c;
  line-height: 1.5;
}

.aprl-review-content p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  line-height: 1.5;
  margin: 8px 0;
}

.aprl-review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aprl-review-date {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: #323232;
  opacity: 0.5;
}

.aprl-review-actions {
  display: flex;
  gap: 8px;
}

.aprl-respond-button,
.aprl-promote-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.aprl-respond-button {
  background: #72147e;
  color: #ffffff;
}

.aprl-promote-button {
  background: #f21170;
  color: #ffffff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .aprl-review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .aprl-review-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .aprl-review-actions {
    width: 100%;
  }

  .aprl-respond-button,
  .aprl-promote-button {
    flex: 1;
    text-align: center;
  }
}