.contact {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 32px;
    box-sizing: border-box;
  }
  
.contact-info {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
}
  
.contact-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.contact-item.clickable {
  cursor: pointer;
}

.contact-item.clickable:hover {
  text-decoration: underline;
}
  
.contact-icon {
  margin-right: 10px;
  color: #35183C;
}
  
.contact p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #35183C;
  margin: 0;
  margin-left: 16px;
}
  