.footer {
  background-color: #72147e;
  color: white;
  width: 100%; /* Full viewport width */
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the content horizontally */
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-color: #72147e;
  z-index: -1;
}

.footer-inner {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 32px; /* Add gap between child elements */
  position: relative;
}

.footer-column {
  /* flex: 1; */
  text-align: left;
  margin-top: 16px;
  min-width: 240px;
}

.footer-column h3 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #ffffff;
  text-decoration: none;
}

.footer-column li a {
  color: #ffffff;
  text-decoration: none;
}
.footer-column li a:hover {
  text-decoration: underline;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
}

.footer-logo img {
  width: 50px;
  margin-right: 12px;
}

.footer-logo p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
}

/* MOBILE */
@media (max-width: 768px) {
  .footer::before {
    display: none;
  }
  
  .footer-inner {
    justify-content: left;
    width: calc(100% - 32px);
    gap: 4px;
  }

  .footer-column {
    min-width: 100%;
    text-align: left;
    margin-top: 8px;
  }

  .footer-logo {
    justify-content: left;
    padding: 16px 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}