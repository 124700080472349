.testimonials-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.testimonials-modal-content {
    background-color: #ffffff;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    padding: 32px;
}

.testimonials-modal-content h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #35183C;
}
  
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}
  
.testimonials-list {
    margin-top: 20px;
}
  
.testimonial-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
}
  
.testimonial-item:last-child {
    border-bottom: none;
}
  
.testimonial-item h3 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #35183C;
}
  
.testimonial-item p {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183C;
}

/* MOBILE */
@media (max-width: 768px) {
    .testimonials-modal-content {
        width: 80%;
        padding: 20px;
    }

    .testimonials-modal-content h2 {
        font-size: 20px;
    }

    .testimonial-item h3 {
        font-size: 16px;
    }

    .testimonial-item p {
        font-size: 14px;
    }
}