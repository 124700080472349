.user-marker {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.user-marker-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.user-marker img {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}

.pulse-ring {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 48px;
    height: 48px;
    border: 2px solid #72147e;
    border-radius: 50%;
    animation: pulse 2s ease-out infinite;
    z-index: 1;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.8;
    }

    70% {
        transform: scale(1.3);
        opacity: 0;
    }

    100% {
        transform: scale(0.95);
        opacity: 0;
    }
}