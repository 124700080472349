.category-bar {
  display: flex;
  overflow-x: scroll; 
  padding: 4px 20px; 
  width: 100%;
  box-sizing: border-box;
  gap: 24px;
  scroll-behavior: smooth; /* Smooth scrolling effect */
  -webkit-overflow-scrolling: touch; 
  flex-wrap: nowrap;
  scrollbar-width: none; 
  -ms-overflow-style: none;  
}

.category-bar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.cb-category-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.category-item {
  position: relative; /* Positioning lock icon */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Opacity transition */
  padding: 0 10px;
  flex-shrink: 0; /* THIS prevents items from shrinking */
}

/* Styles for active category */
.category-item.active {
  transform: scale(1.05); 
  border-radius: 50%; 
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1; /* Ensure full opacity for active state */
}

/* Styles for inactive but available categories */
.category-item:not(.active):not(.locked) {
  opacity: 0.9;
}

/* Styles for locked (unavailable) categories */
.category-item.locked {
  opacity: 0.35; /* 50% of 0.7 */
  cursor: not-allowed;
}

.category-icon {
  position: relative;
  width: 75px; 
  height: 75px; 
  border-radius: 50%;
  transition: box-shadow 0.3s ease, filter 0.3s ease; 
  filter: grayscale(100%); /* Non-active state color */
  margin: 4px; /* Distance between icons */
  object-fit: cover; /* THIS ensures the image covers the container properly */
}

.category-item.active .category-icon {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  filter: grayscale(0%); /* Active state color */
}

.category-item.locked .category-icon {
  filter: grayscale(100%) opacity(50%); /* Greyscale and reduce opacity for locked items */
  cursor: not-allowed;
}

.category-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  transition: font-weight 0.3s ease, opacity 0.3s ease; 
  color: #35183c; /* Non-active state color */
  line-height: 1.2; /* Consistent line height for vertical alignment */
}

.category-item.active .category-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  color: #35183c; /* Active state color */
  margin-top: 8px; /* Aligns with non-active titles */
}


/* Styles for the lock icon */
.cb-lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #35183c;
  z-index: 10;
  cursor: not-allowed;
}

/* Hover effect for locked categories */
.category-item.locked:hover {
  opacity: 0.5;
}

/* MOBILE */
@media (max-width: 768px) {
  .category-bar {
    padding: 4px 10px;
    gap: 12px;
  }

  .category-item {
    padding: 0 5px;
  }

  .category-icon {
    width: 50px;
    height: 50px;
  }

  .category-title {
    font-size: 12px;
  }
}
