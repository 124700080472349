.section-four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-four-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;    
    }

.section-four-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px ;
    margin-top: -8px;
}

.section-four-image {
    max-width: 75%;
    height: auto;
    /* margin-left: 32px; */
    margin-top: 64px;
}

.section-four-subtitle-paragraph {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 0.5;
    line-height: 110%;
    padding: 0 40px ;
    margin-top: 48px;
}

/* MOBILE */
@media (max-width: 768px) {
    .section-four {
      margin-top: 0;
    }
  
    .section-four-title {
      font-size: 32px;
      text-align: left;
      padding: 0 16px;
      margin-top: 0;
      margin-bottom: 32px;
    }
  
    .section-four-subtitle {
      font-size: 18px;
      text-align: left;
      padding: 0 16px;
      margin-top: 0;
    }
  
    .section-four-image {
      max-width: calc(100% - 32px);
      margin-top: 0;
    }
  
    .section-four-subtitle-paragraph {
      font-size: 14px;
      text-align: left;
      padding: 0 16px;
      margin-top: 24px;
      width: calc(100% - 32px);
    }
  }

