.terms-of-service-container {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .tos-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 40px;
    font-weight: 600;
    color: #35183c;
    margin-bottom: 16px;
  }
  
  .tos-last-updated {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 0.5;
    margin-bottom: 32px;
  }
  
  .tos-content {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #35183c;
    line-height: 1.6;
  }

/* THIS removes unnecessary <p>'s from the content */
.tos-content p:empty {
    display: none;
}

.tos-content p:only-child {
    margin-bottom: 0;
}

.tos-content p br:only-child {
    display: none;
}
/* THIS removes unnecessary <p>'s from the content --END */

  .tos-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  
  .tos-content p {
    margin-bottom: 16px;
  }
  
  .tos-content ul, .tos-content ol {
    margin-bottom: 16px;
    padding-left: 20px;
  }
  
  .tos-content li {
    margin-bottom: 8px;
  }
  
  /* MOBILE */
  @media (max-width: 768px) {
    .terms-of-service-container {
      width: calc(100% - 32px);
      margin: 0 16px 32px;
      padding: 0;
    }
  
    .tos-title {
      font-size: 32px;
    }
  
    .tos-content h2 {
      font-size: 20px;
    }
  }