.cta-block {
    text-align: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    /* box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1); */
  
  }
  
  .cta-block h2 {
    font-size: 40px;
    font-weight: bold;
    color: #F21170;
    margin-bottom: 20px;
  }
  
  .cta-block p {
    font-size: 18px;
    color: #35183C;
    margin-bottom: 30px;
  }
  
  .cta-button {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #F21170;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #72147e;
  }