.activity-provider-panel {
    display: grid;
    grid-template-columns: 80px 1fr;
    height: 100vh;
    background-color: hsl(0, 0%, 100%);
    align-items: center;
    margin: 0 -100px;
  }
  
  .app-sidebar {
    height: 650px;
    background-color: white;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    /* padding: 32px; */
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
  }
  
  .app-profile-icon img,
  .app-logout-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin: 24px;
  }
  
  .app-middle-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .app-logout-icon {
    cursor: pointer;
  }
  
  .app-icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .icon-wrapper.active {
    background-color: #72147e;
  }
  
  .icon {
    font-size: 20px;
    color: #35183c;
  }
  
  .icon-wrapper.active .icon {
    color: white;
  }
  
  .app-main-content-container {
    padding-left: 20px;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app-main-content {
    display: flex;
    justify-content: center; /* center */
    align-items: center; /* center */
    width: 100%;
    height: 100%; /* was 94% */
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  /* MOBILE */
  @media (max-width: 768px) {
    .activity-provider-panel {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      height: 100vh;
      position: relative;
  
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .app-sidebar {
      position: fixed;
      bottom: 0;
      height: 80px;
      width: calc(100% - 32px); /* this == left: 16px + right: 16px */
      max-width: 460px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0; /* if we add 0 24px, the bar will be straight, with no borders*/
      box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
    }
  
    .app-profile-icon {
      display: none;
    }
  
    .app-middle-icons {
      margin-left: -16px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 16px;
    }
  
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin: 0;
    }
  
    .app-logout-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin: 0;
    }
  
    .icon {
      font-size: 24px;
    }
  
    .app-main-content-container {
      position: fixed;
      width: calc(100% - 32px);
      max-width: 460px;
      padding: 16px;
      height: calc(100vh - 120px);
      margin-bottom: 80px;
      margin-left: 0;
      margin-right: 0;
    }
  
    .app-main-content {
      align-items: center;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      padding: 0;
    }
  }
  