.rating-stars-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.rating-stars {
    margin: 24px 0;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.rating-stars-text-prompt {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #35183c;
    text-align: center;
    margin: 16px;
    margin-top: -8px;
    padding: 0 16px;
}

.star-icon-transition-colors {
    transition: all 0.3s ease;
    cursor: pointer;
}

/* MOBILE */
@media (max-width: 768px) {
    .rating-stars {
        gap: 2px;
    }

    .star-icon-transition-colors {
        width: 40px;
        height: 40px;
    }
}