.section-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: 700px;
    /* border: 2px solid red; */  
}

.section-two img {
    width: 100%;
    height: auto;
}

/* MOBILE */
@media (max-width: 768px) {
    .section-two {
        height: auto;
        margin-top: 32px;
        margin-bottom: 0;
    }

    .section-two img {
        width: calc(100% - 32px);
        height: auto;
        max-height: 300px;
        object-fit: contain;
    }
}