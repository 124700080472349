.section-eight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-eight-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;
}

.section-eight-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px;
    margin-top: -8px;
}

.section-eight-faq-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px 16px;
    width: 100%;
    max-width: 800px;
    margin-top: 64px;
}

.section-eight-faq-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #35183c;
    cursor: pointer;
}

.section-eight-faq-question {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #35183c;
    display: flex;    
    align-items: center;
    padding: 0 16px;
}

.section-eight-faq-text {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    color: #35183c;    
}

.section-eight-faq-number {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #f22170;
    margin-right: 16px;
}

.section-eight-faq-answer {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 75%;
    margin-left: 16px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
}

.section-eight-faq-item.open .section-eight-faq-answer {
    max-height: 300px;
    padding-top: 0 20px;
    margin-top: 16px;
}

/* MOBILE */
@media (max-width: 768px) {
    .section-eight {
        margin-top: 0;
    }

    .section-eight-title {
        font-size: 32px;
        text-align: left;
        padding: 0 16px;
        margin-top: 0;
        margin-bottom: 32px;
    }

    .section-eight-subtitle {
        font-size: 18px;
        text-align: left;
        padding: 0 16px;
        margin-bottom: 32px;
    }

    .section-eight-faq-container {
        margin: 0 16px;
        flex: 0 0 auto;
        width: calc(100% - 32px);
        border-radius: 10px;
    }
}
