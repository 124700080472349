.onboarding-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  text-align: center;
}

.onboarding-success h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  color: #35183c;
  margin-bottom: 16px;
}

.onboarding-success p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  margin-bottom: 64px;
}

.os-next-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  width: 300px;
  height: 40px;
  background-color: #f21170;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.os-next-button:hover {
  background-color: #72147e;
}

.os-telegram-href {
  color: #72147e;
}

/* Confetti */
.os-confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f21170; /* Default color */
  opacity: 0.7;
  animation: fall 5s linear infinite;
}

.confetti-1 {
  background-color: #35183c;
}

.confetti-2 {
  background-color: #72147e;
}

@keyframes fall {
  0% {
    top: -10%;
    transform: translateX(0) rotateZ(0deg);
  }
  20% {
    transform: translateX(-20px) rotateZ(45deg);
  }
  40% {
    transform: translateX(20px) rotateZ(90deg);
  }
  60% {
    transform: translateX(-20px) rotateZ(135deg);
  }
  80% {
    transform: translateX(20px) rotateZ(180deg);
  }
  100% {
    top: 110%;
    transform: translateX(-20px) rotateZ(225deg);
  }
}

/* Randomize confetti positions and animations */
.os-confetti-container .confetti:nth-child(even) {
  animation-duration: 3.5s;
  animation-delay: 1s;
  left: 25%;
}

.os-confetti-container .confetti:nth-child(4n) {
  animation-duration: 4.5s;
  animation-delay: 0.5s;
  left: 75%;
}

.os-confetti-container .confetti:nth-child(3n) {
  animation-duration: 3s;
  animation-delay: 1.5s;
  left: 50%;
}

/* MOBILE */
@media (max-width: 768px) {
  .onboarding-success {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: left;
  }

  .onboarding-success h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .onboarding-success p {
    font-size: 14px;
    margin-bottom: 24px; 
  }

  .os-next-button {
    width: 100%; 
    margin-top: 16px;
  }

  .os-confetti-container .confetti {
    width: 8px;
    height: 8px;
  }
  
  .os-confetti-container .confetti:nth-child(2n) {
    display: none; /* Reduce number of particles for better performance */
  }
}