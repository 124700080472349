.admin-panel {
    display: grid;
    grid-template-columns: 80px 1fr;
    height: 100vh;
    background-color: hsl(0, 0%, 100%);
    align-items: center;
    margin: 0 -100px;     
  }
  
  .sidebar {
    height: 650px;

    background-color: white;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
  }
  
  .profile-icon img,
  .logout-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin: 24px;
  }
  
  .middle-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .logout-icon {
    cursor: pointer;
  }
  
  .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .icon-wrapper.active {
    background-color: #72147e;
  }
  
  .icon {
    font-size: 20px;
    color: #35183c;
  }
  
  .icon-wrapper.active .icon {
    color: white;
  }
  
  .main-content-container {
    padding-left: 20px;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  /* this is the rectangle where the content is displayed */
  .main-content {
    display: flex;
    justify-content: center; /* center */
    align-items: center; /* center */

    width: 100%;
    height: 100%; /* was 94% */
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 1px 32px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }