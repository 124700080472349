.app-our-locations {
  font-family: "Rubik", sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
}

.app-ol-tabs {
  display: flex;
  overflow-x: auto;
  border-bottom: 1px solid #ddd;
}
/* Tabs with city names */
.app-ol-tab {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: #35183c;
  transition: all 0.3s ease;
  text-wrap: nowrap;
}

.app-ol-tab.active {
  font-weight: 600;
  border-bottom-color: #f21170;
  color: #f21170;
}

/* List with addrsses related to chosen city name */
.app-ol-address-list {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  list-style-type: none;
  padding: 8px 20px;
}

.app-ol-address-item {
  margin-bottom: 32px;
}

.app-ol-additional-info {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-top: -8px;
  margin-left: 24px;
}

@media (max-width: 768px) {
  .app-ol-address-list {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .app-ol-tab {
    font-size: 14px;
    padding: 8px 16px;
  }
  
}