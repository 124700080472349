.app-main-image {
    width: 100%;
    height: 230px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .app-main-image::after {
    content: '';
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.30) 100%);
    z-index: 0;
  }
  
  .app-logo-text-overlay {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1;
  }
  
  .app-logo {
    width: 75px;
    height: 75px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 3px solid #ffffff;
    margin-right: 16px; /* Space between logo and text */
  }
  
  .app-title-inn-wrapper {
    display: flex;
    flex-direction: column; /* Align title and inn vertically */
  }
  
  .app-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
  }

  .app-verification-badge {
    display: inline-flex;
    margin-left: 4px;
    color: #72147e;
    font-size: 0.8rem;
    vertical-align: baseline;
  }
  
  .app-inn {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    margin-top: 8px; /* Space between title and INN */
    color: #ffffff;
    text-align: left;
  }