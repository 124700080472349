.section-nine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-nine-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;
}

.section-nine-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px;
    margin-top: -8px;
}

.sn-options-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-radius: 10px;
    margin-top: 64px;
    margin-bottom: 32px;
 }

 .sn-option {
    flex: 1;
    display: flex;
    align-items: flex-start;
    text-align: left;
 }

 .sn-option-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* THIS prevents the conent inside the number + path container from shrinking */
    width: 150px;
    height: 180px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
 }

 .sn-option-content {
    flex-grow: 1;
 }

.sn-option-gray .sn-option-number-container {
    background-color: #f0f0f0;
}

.sn-option-purple .sn-option-number-container {
    background-color: #F21170;
}

.sn-option-number {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 50px;
    font-weight: 700;
    margin: 0;
}

.sn-option-number-text {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 30px;
    font-weight: 700;
    margin-top: 16px;
}

.sn-option-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.sn-option-description {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
}

.sn-option-gray .sn-option-number,
.sn-option-gray .sn-option-number-text,
.sn-option-gray .sn-option-title,
.sn-option-gray .sn-option-description {
    color: #323232;
    opacity: 50%;
}

.sn-option-purple .sn-option-number,
.sn-option-purple .sn-option-number-text {
    color: #ffffff;
}

.sn-option-purple .sn-option-title,
.sn-option-purple .sn-option-description {
    color: #35183c;
}

.sn-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    background-color: #F21170;
    /* background-image: linear-gradient(98.68deg, #72147e -3.73%, #F21170 96.53%); */
    border-radius: 10px;
    /* box-shadow: 0 4px 46px rgba(242, 17, 112, 0.3); */

    margin-top: 40px;
    margin-bottom: 96px;
    padding: 0 80px;
    height: 50px;
    /* border: 2px solid blue;  */
}

.sn-button:hover {
    background-color: #72147e;
    box-shadow: 0 4px 46px rgba(114, 20, 126, 0.3);
    /* background-image: linear-gradient(98.68deg, #72147e -3.73%, #F21170 96.53%); */
    transition: background-color 0.5s;
}

.animated-text {
    position: relative;
    display: inline-block;
    color: inherit;
    transition: color 0.4s ease;
    padding: 0 12px;
}

.animated-text::before {
    content: '';
    position: absolute;
    left: -6px;
    right: -6px;
    top: 0;
    bottom: 0;
    background-color: #F21170;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s ease;
    z-index: -1;
}

.animated-text.animate::before {
    transform: scaleX(1);
}

.animated-char {
    display: inline-block;
    color: inherit;
    transition: color 0.4s ease;
}

.animated-char.space {
    width: 0.3em;
}

.animated-text.animate .animated-char {
    animation: changeColor 0.2s forwards;
}

@keyframes changeColor {
    from { color: inherit; }
    to { color: #ffffff; }
}

/* MOBILE */
@media (max-width: 768px) {
    .section-nine {
        margin-top: 16px;
    }

    .section-nine-title {
        font-size: 32px;
        text-align: left;
        padding: 0 16px;
        margin-top: 0;
        margin-bottom: 32px;
    }

    .section-nine-subtitle {
        font-size: 18px;
        text-align: left;
        padding: 0 16px;
        margin-bottom: 32px;
        width: calc(100% - 32px);
        margin-top: 0;
    }
    
    .sn-options-container {
        flex-direction: column;
        gap: 24px;
        margin-top: 0;
        padding: 0 16px;
      }
    
      .sn-option {
        width: 100%;
        padding: 16px;
      }

      .sn-option.sn-option-gray,
      .sn-option.sn-option-purple {
        padding: 0;
      }

      .sn-option-number-container {
        width: 100px;
        height: 220px; 
        margin: 0;
      }
    
      .sn-option-content {
        /* width: calc(100% - 136px); Adjust for the number container width + margin */
        padding: 0 16px;
      }

      .sn-option-title {
        margin: 0 0 8px;
      }

      .sn-option-description {
        margin: 0;
      }
    
      .sn-button {
        width: calc(100% - 32px);
        margin-top: 0;
        margin-bottom: 32px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
      }
    }