.section-heading {
    font-size: 24px;
    color: #35183c;
    margin: 32px 0 0;
    text-align: left;
  }

  .section-heading {
    box-sizing: border-box;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .section-title {
    margin-bottom: 0px;
    font-size: 24px;
    /* text-align: left; */
    color:#35183C
  }
  
  .navigation-arrows {
    display: flex;
    gap: 4px;
  }
  
  .navigation-arrows .prev,
  .navigation-arrows .next {
    pointer-events: all;
    color: #35183C;
    border-radius: 10px;
    border-style:hidden;
    padding: 12px;
    cursor: pointer;
  }
  
  .navigation-arrows .prev:hover {
    color:#F21170;
    transition: background-color 0.3s;
  }
  
  .navigation-arrows .next:hover {
    color: #F21170;
    transition: background-color 0.3s;
  }