.section-heading {
  box-sizing: border-box;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  margin-bottom: 0px;
  font-size: 24px;
  /* text-align: left; */
  color: #35183c;
}

.app-current-location-banner {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  padding-bottom: 0;
  margin: 0 auto;
  max-width: 1100px;
  color: #35183c;
  font-family: "Rubik", sans-serif;
}

.navigation-arrows {
  display: flex;
  gap: 4px;
}

.navigation-arrows .prev,
.navigation-arrows .next {
  pointer-events: all;
  color: #35183c;
  border-radius: 10px;
  border-style: hidden;
  padding: 12px;
  cursor: pointer;
}

.navigation-arrows .prev:hover {
  color: #f21170;
  transition: background-color 0.3s;
}

.navigation-arrows .next:hover {
  color: #f21170;
  transition: background-color 0.3s;
}

/* RatingStars */
.app-review-container {
  max-width: 1100px;
  margin: 16px auto 24px;
  padding: 0;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #d1d5db;
}

/* MOBILE */
@media (max-width: 768px) {
  /* General layout */
  div.pp-main-image {
    width: 92%;
    margin: 16px;
  }

  .app-current-location-banner {
    padding: 16px;
    margin: 0;
  }

  div.section-heading {
    margin: 0;
  }

  img.gallery-image {
    height: 260px;
  }

  /* Schedule */
  .schedule {
    padding: 10px;
    overflow: hidden;
  }

  .days {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  .days::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  .day {
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-right: 10px;
  }

  .schedule table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .schedule table::-webkit-scrollbar {
    display: none;
  }

  /* OurLocations */
  .our-locations {
    padding: 10px;
    overflow: hidden;
  }

  .location-list {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .location-list::-webkit-scrollbar {
    display: none;
  }

  .location-item {
    flex: 0 0 auto;
    width: 80%; /* Adjust as needed */
    margin-right: 15px;
    white-space: normal; /* Allow text wrapping within each item */
  }

  .app-review-container {
    padding: 0;
    margin: 0;
  }
}
