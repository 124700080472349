.stepper {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.step-indicator {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 600;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    background-color: #ffffff;
    border: 2px solid #323232;
    color: #323232;
}

.step.active .step-indicator {
    border-color: #35183c;
    color: #35183c;
}

.step.completed .step-indicator {
    background-color: #72147e;
    border-color: #72147e;
    color: #ffffff;
}

.step-title {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #35183c;
}

.step:not(.active):not(.completed) .step-indicator,
.step:not(.active):not(.completed) .step-title {
    color: #323232;
    opacity: 0.25;
    font-weight: 600;
}