.parent-reviews-list {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.prl-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prl-list-title h2 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #35183c;
}

.prl-list-count {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  opacity: 0.5;
}

.prl-error {
  color: #f21170;
  text-align: center;
  padding: 16px;
}

.prl-empty-state {
  text-align: center;
  padding: 32px;
  color: #666;
  font-family: "Rubik", sans-serif;
}

.prl-reviews-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.prl-review-card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 16px;
}

.prl-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.prl-review-header h3 {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #35183c;
  margin: 0;
}

.prl-review-content {
  margin-bottom: 16px;
}

.prl-review-content span {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #35183c;
  line-height: 1.5;
}

.prl-review-content p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
  line-height: 1.5;
  margin: 8px 0;
}

.prl-review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prl-review-date {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: #323232;
  opacity: 0.5;
}

.prl-review-actions {
  display: flex;
  gap: 8px;
}

.prl-edit-button,
.prl-delete-button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.prl-edit-button {
  background: #72147e;
  color: #ffffff;
}

.prl-delete-button {
  background: #f21170;
  color: #ffffff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .parent-reviews-list {
    padding: 0;
  }

  .prl-review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .prl-review-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .prl-review-actions {
    width: 100%;
  }

  .prl-edit-button,
  .prl-delete-button {
    flex: 1;
    text-align: center;
  }
}
