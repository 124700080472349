.onboarding-step-two {
    max-width: 1100px;
    margin: 0 auto;
    padding: 24px;
  }
  
.onboarding-step-two h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #35183c;
    margin-top: 24px;
    margin-bottom: 48px;
}
 
.onboarding-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ost-form-container {
    width: 80%;
    margin-left: 400px;
}

.ost-form-group {
    margin-bottom: 16px;
}
  
.ost-form-group label {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
    color: #35183c;
}
  
.ost-form-group input,
.ost-form-group select {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 8px;
    border: 1px solid #35183c;
    box-sizing: border-box;
    border-radius: 10px;
}

.ost-logo-upload-area {
    width: 100%;
    height: 150px;
    border: 2px dashed #35183c;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease;
}

.ost-upload-placeholder {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
}

.ost-logo-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.ost-form-group input:focus,
.ost-form-group select:focus {
    outline: none;
    border-color: #72147e;
    box-shadow: 0 0 0 2px rgba(114, 20, 126, 0.2);
}
  
.ost-error-message {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #f21170;
    margin-bottom: 16px;
}
  
.ost-next-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    width: 180px;
    max-width: 240px;
    height: 40px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    float: right;
    margin-top: 24px;
}
  
.ost-next-button:hover {
    background-color: #72147e;
}

/* MOBILE */
@media (max-width: 768px) {
    .stepper {
        display: none;
    }
    
    .onboarding-step-two {
      padding: 16px;
    }
  
    .onboarding-step-two h2 {
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 24px;
    }
  
    .onboarding-container {
      flex-direction: column;
    }
  
    .ost-form-container {
      width: 100%;
      margin-left: 0;
    }
  
    .ost-form-group label {
      font-size: 16px;
    }
  
    .ost-form-group input,
    .ost-form-group select {
      font-size: 16px;
    }

    .ost-form-group select option {
        padding: 12px;
        font-size: 16px;
    }
  
    .ost-next-button {
      width: 100%;
      margin-top: 16px;
      float: none;
    }
  }