.fairytale-list {
    font-family: "Rubik", sans-serif;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }
  
  .fl-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fl-list-title h2 {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #35183c;
  }
  
  .fl-list-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    opacity: 50%;
  }
  
  .fl-add-button {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    padding: 0 40px;
    height: 40px;
    background-color: #f21170;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .fl-add-button:hover {
    background-color: #72147e;
  }
  
  .fl-table-container {
    overflow-x: auto;
    margin-top: 32px;
    width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  th, td {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  th {
    background-color: #f9f9f9;
    color: #35183c;
    font-weight: 400;
    height: 40px;
  }
  
  .fl-title-column {
    width: 70%;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #35183c;
  }
  
.fl-date-column {
    width: 30%;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #35183c; 
}
  
  .fl-tale-thumbnail {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle;
  }
.fl-fairytale-row {
  color: #35183c; 
  cursor: pointer;
}

.fl-fairytale-row:hover {
  color: #f21170;
}