.testimonials {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
  box-sizing: border-box;  
}
  
.testimonials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100px; /* Adjust height if needed */
}
  
.testimonial {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: left;
}

.testimonial-quote-mark {
  font-family: "Rubik", sans-serif;
  font-size: 90px;
  font-weight: 500;
  margin-top: 32px;
  color: #35183C;
  padding: 8px;
}
  
.testimonial p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #35183C;
  margin-top: -32px;
  padding: 8px;
}
  
.testimonial .parent-name {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #F21170;
  margin-top: 16px;
  padding: 8px;
}