.what-we-offer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;  
}
  
.what-we-offer ul {
  list-style-type: none;
  padding-left: 0;
}

.what-we-offer ul li {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #35183C;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.wwo-icon-item {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  color: #35183C;
  padding: 8px;
}

@media (max-width: 768px) {
  .wwo-icon-item {
    display: none;
  }
}