.section-five {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: 64px;
}

.section-five-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: #35183c;
    line-height: 110%;
    text-transform: uppercase;    
}

.section-five-subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #35183c;
    line-height: 110%;
    padding: 0 40px ;
    margin-top: -8px;
}

/* Feature desctiption */
.sf-features-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 24px 48px;
    /* box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1); */
    width: 100%;
    max-width: 800px;
    margin-top: 48px;
}

.sf-features-table {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #35183c;
}

.sf-category-row td {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  color: #35183c;
  background-color: #f0f0f0;
  text-transform: uppercase;
}

.sf-check {
    color: #4caf50;
}

.sf-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  background-color: #F21170;
  padding: 0 80px;
  width: 400px;
  height: 50px;
  margin-top: 64px;
}

.sf-button:hover {
  background-color: #74127e;
}

/* MOBILE */
@media (max-width: 768px) {
  .section-five {
    margin-top: 32px;
  }
  
  .section-five-title {
    font-size: 32px;
    text-align: left;
    padding: 0 16px;
    margin-top: 0;
    margin-bottom: 32px;
    }
  
  .section-five-subtitle {
    font-size: 18px;
    text-align: left;
    padding: 0 16px;
    margin-top: 0;
  }
  
  .sf-features-container {
    margin-top: 32px;
    padding: 0;
  }

  .sf-features-table {
    width: 100%;
  }

  /* .sf-features-table td {
    padding-right: 0;
  } */

  .sf-check {
    margin-right: 0;
    color: #4caf50;
  }

  .sf-button {
    width: calc(100% - 32px);
    margin-top: 24px;
    margin-bottom: 0;
  }
}