.review-form-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0;
  display: none;
  font-family: "Rubik", sans-serif;
}

.review-form-container.visible {
  display: block;
}

.rf-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 24px 20px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.rf-rating-section {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}

.rf-section {
  margin-bottom: 24px;
  padding: 0;
}

/* NEW */
.rf-date-input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    padding: 0 24px;

    color: #323232;
    opacity: 0.5;
}

.rf-date-input::-webkit-datetime-edit {
    color: #323232;
    opacity: 0.5;
}

.rf-date-input::-webkit-datetime-edit-text {
    padding: 0 4px;
}
/* NEW end */

.rf-date-input,
.rf-input {
  width: 100%;
  padding: 12px 0;
  text-indent: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #35183c;
}

.rf-title,
.rf-description {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #35183c;
  margin-bottom: 16px;
}

.rf-textarea {
  width: 100%;
  height: 150px;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  resize: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 1.5;
}

.rf-input::placeholder,  
.rf-textarea::placeholder {
    color: #323232;
    opacity: 0.5;
}

.rf-verify-section {
  margin-bottom: 24px;
}

.rf-verify-label {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.rf-checkbox {
  margin-top: 4px;
}

.rf-verify-text {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: #323232;
  opacity: 0.5;
  line-height: 1.5;
}

.rf-submit-button {
  width: 100%;
  padding: 16px;
  background-color: #72147e;
  color: white;
  border: none;
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rf-submit-button:disabled {
    background-color: #323232;
    opacity: 0.5;
    cursor: not-allowed;
}

.rf-submit-button:hover {
  background-color: #621170;
}

@media (max-width: 768px) {
  .review-form-container {
    padding: 0 4px;
  }

  .rf-content {
    padding: 16px;
  }

  .rf-title {
    font-size: 20px;
  }

  .rf-description {
    font-size: 20px;
  }
}
